.card {
  border: 1px solid $white;
  border-radius: 0;

  &-title {
    color: $body-color;
  }

  &-custom-step {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding-left: 3.5rem;

    &-title {
      color: $blue;
      font-weight: $font-weight-bold;
      font-family: $font-family-sans-serif-secondary;
      padding: 1rem 0rem;
    }

    &-body {
      padding: 1rem 0rem;
    }
  }
}
