.icon {
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  
    &.icon-metanavigation {
      vertical-align: top;
    }
  
    &.icon-thick {
      stroke-width: 1;
    }
  
    &.icon-md {
      width: $icon-size-md;
      height: $icon-size-md;
    }
  
    &.icon-lg {
      width: $icon-size-lg;
      height: $icon-size-lg;
    }
  
    &.icon-xl {
      width: $icon-size-xl;
      height: $icon-size-xl;
    }

    &.icon-xxl {
      width: $icon-size-xxl;
      height: $icon-size-xxl;
    }

    &-markup {
      img {
        width: 23px;
        margin-bottom: 1rem;
      }
    }
  }

  
@function svg-icon-arrow-up($colour) {
@return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.886 28.886"><g transform="translate(0)"><path d="M0,0H28.886V28.886H0Z" transform="translate(0 0)" fill="none"/><path d="M13.629,4l-1.7,1.7,6.716,6.728H4v2.407H18.648l-6.716,6.728,1.7,1.7,9.629-9.629Z" transform="translate(0.815 0.814)" fill="#{$colour}"/></g></svg>');
}

@function svg-icon-email($colour) {
@return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.732 46.732"><path d="M0,0H46.732V46.732H0Z" fill="none"/><path d="M40.944,7.894A3.906,3.906,0,0,0,37.049,4H5.894A3.906,3.906,0,0,0,2,7.894V31.261a3.906,3.906,0,0,0,3.894,3.894H37.049a3.906,3.906,0,0,0,3.894-3.894Zm-3.894,0L21.472,17.63,5.894,7.894Zm0,23.366H5.894V11.789l15.577,9.736,15.577-9.736Z" transform="translate(1.894 3.789)" fill="#{$colour}"/></svg>');
}

@function svg-icon-more($colour) {
@return url(
     'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none"/><path d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" fill="#{$colour}"/></svg>');
}

@function svg-icon-question($colour) {
@return url(
     'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.475 54.475"><path d="M0,0H54.475V54.475H0Z" fill="none"/><path d="M31.507,6.54V22.428H9.2L7.856,23.767,6.54,25.084V6.54H31.507M33.777,2H4.27A2.276,2.276,0,0,0,2,4.27V36.047l9.079-9.079h22.7a2.276,2.276,0,0,0,2.27-2.27V4.27A2.276,2.276,0,0,0,33.777,2Zm11.349,9.079h-4.54V31.507H11.079v4.54a2.276,2.276,0,0,0,2.27,2.27H38.317L47.4,47.4V13.349A2.276,2.276,0,0,0,45.126,11.079Z" transform="translate(2.54 2.54)" fill="#{$colour}"/></svg>');
}

@function svg-icon-shopping-cart($colour) {
    @return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path d="M0,0H28V28H0Z" fill="none"/><path d="M17.975,14.833a2.322,2.322,0,0,0,2.042-1.2L24.193,6.06a1.162,1.162,0,0,0-1.015-1.727H5.912L4.815,2H1V4.333H3.333l4.2,8.855L5.958,16.035A2.338,2.338,0,0,0,8,19.5H22V17.167H8l1.283-2.333ZM7.02,6.667H21.195L17.975,12.5H9.785Zm.98,14A2.333,2.333,0,1,0,10.333,23,2.33,2.33,0,0,0,8,20.667Zm11.667,0A2.333,2.333,0,1,0,22,23,2.33,2.33,0,0,0,19.667,20.667Z" transform="translate(0.167 0.333)" fill="#{$colour}"/></svg>');
}

@function svg-icon-search($colour) {
    @return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path d="M0,0H28V28H0Z" fill="none"/><path d="M17.583,15.833h-.922l-.327-.315a7.595,7.595,0,1,0-.817.817l.315.327v.922l5.833,5.822,1.738-1.738Zm-7,0a5.25,5.25,0,1,1,5.25-5.25A5.243,5.243,0,0,1,10.583,15.833Z" transform="translate(0.5 0.5)" fill="#{$colour}"/></svg>');
}

@function svg-icon-support($colour) {
    @return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path d="M0,0H29.562V29.562H0Z" fill="none"/><path d="M13.086,28.825V24.391A11.7,11.7,0,1,1,25.4,12.7c0,6.1-4.237,12.231-10.556,15.273ZM13.7,3.463a9.238,9.238,0,0,0,0,18.476h1.848v2.833c4.483-2.833,7.39-7.489,7.39-12.071A9.241,9.241,0,0,0,13.7,3.463ZM12.47,17.628h2.463v2.463H12.47Zm2.463-1.848H12.47c0-4,3.7-3.7,3.7-6.159a2.463,2.463,0,1,0-4.927,0H8.775a4.927,4.927,0,1,1,9.854,0C18.628,12.7,14.933,13.009,14.933,15.781Z" transform="translate(0.463 0.232)" fill="#{$colour}"/></svg>');
}

@function svg-icon-login($colour) {
    @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path d='M0,0H30V30H0Z' fill='none'/><path d='M14.5,2A12.5,12.5,0,1,0,27,14.5,12.5,12.5,0,0,0,14.5,2ZM8.338,22.35c.537-1.125,3.812-2.225,6.162-2.225s5.638,1.1,6.163,2.225a9.907,9.907,0,0,1-12.325,0ZM22.45,20.538c-1.788-2.175-6.125-2.913-7.95-2.913s-6.162.737-7.95,2.913a10,10,0,1,1,15.9,0ZM14.5,7a4.375,4.375,0,1,0,4.375,4.375A4.364,4.364,0,0,0,14.5,7Zm0,6.25a1.875,1.875,0,1,1,1.875-1.875A1.872,1.872,0,0,1,14.5,13.25Z' transform='translate(0.5 0.5)' fill='#{$colour}'/></svg>");
}

@function svg-icon-close($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M12.665 12.005l6.629-6.931c0.088-0.087 0.142-0.208 0.142-0.341s-0.054-0.254-0.142-0.341l-0-0c-0.087-0.086-0.206-0.14-0.338-0.14s-0.252 0.053-0.338 0.14l0-0-6.595 6.917-6.619-6.898c-0.088-0.092-0.211-0.149-0.348-0.149-0.265 0-0.48 0.215-0.48 0.48 0 0.128 0.050 0.245 0.133 0.331l-0-0 6.629 6.931-6.629 6.926c-0.088 0.087-0.142 0.208-0.142 0.341s0.054 0.254 0.142 0.341l0 0c0.088 0.082 0.206 0.133 0.336 0.134h0c0.136-0.001 0.258-0.057 0.345-0.149l0-0 6.634-6.902 6.581 6.902c0.087 0.091 0.21 0.148 0.345 0.149h0c0.001 0 0.002 0 0.003 0 0.265 0 0.48-0.215 0.48-0.48 0-0.131-0.052-0.249-0.137-0.336l0 0z' fill='#{$colour}'/></svg>");
}

@function svg-icon-download($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$colour}'><g><rect fill='none' height='24' width='24'/></g><g><path d='M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z'/></g></svg>");
}

@function svg-icon-warning($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$colour}'><g><rect fill='none' height='24' width='24'/></g><g><g><g><path d='M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z'/><polygon points='13,16 11,16 11,18 13,18'/><polygon points='13,10 11,10 11,15 13,15'/></g></g></g></svg>");
}

@function svg-icon-external-link($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$colour}'><path d='M0 0h24v24H0z' fill='none'/><path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/></svg>");
}

@function svg-icon-facebook($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' height='32px' viewBox='0 0 32 32' width='32px'><path fill='#{$colour}' d='M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z'/></svg>");
}

@function svg-icon-linkedin($colour) {
  @return url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' height='32px' viewBox='0 0 32 32' width='32px'><path fill='#{$colour}' d='M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM12 26h-4v-14h4v14zM10 10c-1.106 0-2-0.894-2-2s0.894-2 2-2c1.106 0 2 0.894 2 2s-0.894 2-2 2zM26 26h-4v-8c0-1.106-0.894-2-2-2s-2 0.894-2 2v8h-4v-14h4v2.481c0.825-1.131 2.087-2.481 3.5-2.481 2.488 0 4.5 2.238 4.5 5v9z'/></svg>");
}

@mixin icon-caret-down {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M3886.877,524.493l5.983,5.96,5.759-5.96' transform='translate(-3886.348 -523.962)' fill='none' stroke='%23015181' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
}

@mixin icon-caret-up {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M3886.877,524.493l5.983,5.96,5.759-5.96' transform='translate(3899.159 531.203) rotate(180)' fill='none' stroke='%23015181' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
      }

@mixin icon-caret-init {
  background-repeat: no-repeat;
  height: $icon-size-md;
  width: $icon-size-md;
  position: absolute;
  top: 80%;
  left: 22%;
}

@mixin icon-caret-init-dropdown {
  background-repeat: no-repeat;
  height: $icon-size-md;
  width: $icon-size-md;
  position: absolute;
  top: 18.5%;
  left: 83%;
}
      
  @mixin icon-init {
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

.icon-container {
    background-color: $white;
    @include icon-init;
    &-blue {
      background-color: $blue;
      @include icon-init;
    }
}
  .search {
    background-color: $blue;
    background-image: svg-icon-search('%23FFFFFF');
    background-repeat: no-repeat;
    height: $icon-size-md;
    width: $icon-size-md;
    position: relative;
    top: -15%;
    left: -100%;
    display: inherit;
  }

  .support {
    background-color: $white;
    background-image: svg-icon-support('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    position: relative;
    top: 8%;
    left: 15%;
    display: inherit;
  }

  .download {
    background-image: svg-icon-download('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    display: inline-block;
    vertical-align: middle;
  }

  .warning-dangerous {
    background-image: svg-icon-warning('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    display: inline-block;
    vertical-align: middle;
  }

  .external-link {
    background-image: svg-icon-external-link('%230076BD');
    background-repeat: no-repeat;
    height: 1.9rem;
    width: $icon-size-lg;
    display: inline-block;
    vertical-align: middle;
  }

  .shopping-cart {
    background-color: $white;
    background-image: svg-icon-shopping-cart('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    position: relative;
    top: 2px;
    left: 2px;
    display: inherit;
  }

  .login {
    background-color: $white;
    background-image: svg-icon-login('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    position: relative;
    top: 2px;
    left: 2px;
    display: inherit;
  }

  .arrow-down {
    background-image: svg-icon-arrow-up('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-md;
    width: $icon-size-md;
    display: inherit;
    transform: rotate(90deg);
    margin-bottom: -5px; //korrektur nach transformation
  }

  .arrow-right {
    background-image: svg-icon-arrow-up('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-md;
    width: $icon-size-md;
    position: relative;
    display: inherit;
    transform: rotate(0deg);
  }

  .arrow-left {
    background-image: svg-icon-arrow-up('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    position: relative;
    top: 30%;
    display: inherit;
    transform: rotate(180deg);
  }

  .show-more {
    background-image: svg-icon-more('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-md;
    width: $icon-size-md;
    position: relative;
    top: 20%;
    display: inherit;
  }

  .icon-close {
    background-image: svg-icon-close('%230076BD');
    background-repeat: no-repeat;
    height: $icon-size-lg;
    width: $icon-size-lg;
    position: relative;
    display: inherit;
  }

  .facebook-link {
    background-image: svg-icon-facebook('%230076BD');
    background-repeat: no-repeat;
    height: 1.9rem;
    width: $icon-size-lg;
    display: inline-block;
    vertical-align: middle;
  }

  .linkedin-link {
    background-image: svg-icon-linkedin('%230076BD');
    background-repeat: no-repeat;
    height: 1.9rem;
    width: $icon-size-lg;
    display: inline-block;
    vertical-align: middle;
  }

