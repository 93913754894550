// Gültig für alle Dropdowns
.dropdown-item {
  border-top: 1px solid $white;
}

//dropdownMenuMyAccount
.menuMyAccount::after {
    vertical-align: 0.5em !important;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    color: $blue;
  }

//Language Dropdown Menu in Menu Navigation
.languageDropdown {
  .dropdown-menu {
    min-width: 3rem;
  }
  .dropdown-item {
    padding: 0.5rem;
  }
}

//Unit Price Dropdown in Detail Page
.unit-prices {
  display: grid;

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      border: none;
      width: auto;
      height: auto;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.001' height='8.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E");
      transition: all 0.2s ease-in-out;
    }

    &.show {
      background-color: $white;

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    width: 100%;
  }

  // .dropdown-menu[data-bs-popper] {
  //   @include media-breakpoint-up(xl) {
  //     left: -10.7rem !important;
  //   }
  // }
  
  .unit-price-item {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    font-weight: $font-weight-normal;

    .price-new {
      color: $secondary;
      font-size: $h3-font-size;
      font-weight: $font-weight-bold;
    }

    .price-unit {
      margin-left: calc($spacer / 2);
    }

    .price-tax {
      font-size: $font-size-base * .875;
      font-style: italic;
      color: $gray-500;
    }
  }
}

.asantys-select {
  .btn-block {
    text-align: left;
    background-color: $blue-light;
    width: 100%;
  }

  .dropdown-menu.show {
    margin-left: initial;
    margin: auto;
    display: contents;

     .dropdown-item {
     padding: 0.5rem !important;

    &:hover,
    &:focus {
      color: $white;
    }

  }
}


//removes default caret
  .dropdown-toggle::after {
    margin-left: 75%;
  }

}