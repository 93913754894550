@use "sass:math";

/*Brick*/
.custom {
  &-accordion {
    display: inline-block;
      width:100%;
      border: solid $gray-200;
      border-width: 1px;

    a {
      margin: 0;
      padding: 1rem 0;
    }

   [data-toggle="collapse"] {

    &[aria-expanded="false"] {
      background-color: $white;
      .icon-caret {
        @include icon-caret-init;
        @include icon-caret-down;
      }
    }

    &[aria-expanded="true"]{
      background-color: $blue-light;
      .icon-caret {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M3886.877,524.493l5.983,5.96,5.759-5.96' transform='translate(3899.159 531.203) rotate(180)' fill='none' stroke='%23015181' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
        @include icon-caret-init;
        @include icon-caret-up;
      }
    }

  }

  .collapse {
    background-color: $blue-light;
  }


  }

}


// TODO: prüfen, da seit BT 5 so nicht mehr richtig
.accordion {
  [data-toggle="collapse"] {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    background-color: $white;

    &:active::before {
      background-image: svg-icon-more('%23ffffff');
    }

    &::before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background-image: svg-icon-more('%230076BD');
      background-repeat: no-repeat;
      height: $icon-size-md;
      width: $icon-size-md;
      position: relative;
      top: 10%;
      display: inherit;
    }

    &[aria-expanded="true"]:active::before {
      background-image: svg-icon-more('%23ffffff');
    }
    &[aria-expanded="true"]::before {
      background-image: svg-icon-more('%230076BD');
      @extend .border-white;
      filter: invert(0);
      transform: rotate(45deg);
    }
  }
}

.accordion-header {
  @include font-size($h2-font-size);
  font-family: $font-family-sans-serif-secondary;
}

.accordion-button {
  font-weight: $font-weight-bold;

  &:not(.collapsed) {
    box-shadow: none;
  }

  // Accordion icon
  &::after {
    order: -1;
    margin-left: 0;
    margin-right: $spacer;
  }
}

.accordion-body {
  padding: $accordion-body-padding-y $accordion-body-padding-x $accordion-body-padding-y calc($accordion-body-padding-x + $accordion-icon-width + $spacer);
}

.accordion-collapse {
  background-color: $accordion-button-active-bg;
}

/*ToDo: ist das local oder Global?*/
.categories {
  &.collapse.show {
    display: flex;
  }
}

// Accordions in Show More Section
.collapse,
.collapsing {
  > .accordion-item:first-of-type {
    border-top: 0;
  }
}

// Special CSS Custom Step Accordions
#accordionCustomStep {

  /* when modal active */
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
  
  // position: static;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // overflow: auto;

  .accordion-button{
    &:after{
      content: none;
    }
    
    flex-wrap: wrap;
    background-color: transparent;
    padding-left: 3.5rem;

    .toolbox-element {
      width: 100%;
      padding-left: 2.25rem;

      p {
        margin-bottom: 0;
      }
    }
  }

.accordion-header {
  .toolbox-element p{
    width: 100%;
    padding: 0rem 3.35rem;
    margin-top: -0.3rem;
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    color: $blue-dark;
    text-align: left;
  }
}
.shipping-invoice-addresses {
  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;

    #shipping-item,
    #invoice-item {
      height: 100%;
    }
  }
  
  
}

}