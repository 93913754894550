.list-group {
  &.profil {
    .list-group-item {
      display: flex;
      align-self:flex-start;
      background-color: $list-group-bg;
      border: $list-group-border-width solid $list-group-border-color;
      border-bottom: 0;
      padding: 0.8rem 1.25rem;

      &::before {
        flex-shrink: 0;
        width: 1.25rem;
        // height: 1.25rem;
        // margin-left: auto;
        margin-right: 1rem;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23015181'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        // background-size: 1.25rem;
        transform: rotate(-90deg);
      }

      span{
        width: 100%;
        font-weight: $font-weight-bold;
        font-size:  $font-size-base;
        color: $blue-dark;
        text-align: left;
      }

        &:last-child {
          border-bottom: $list-group-border-width solid $list-group-border-color;
      }

        &:hover,
        &:focus {
          background-color: $blue-light;
        }
    }
  }
}