.form {

  &-check {
    &-asantys-terms {
      a{
        color: $body-color;
      }

      .form-check {
        display: inline-block;
      }
    }
  }
  
  &-switch {
    .form-check-input {
      @include border-radius($form-switch-border-radius, $form-switch-border-radius);
      height: $form-switch-height;
      margin-top: ($line-height-base - $form-switch-height) * .5; // line-height minus check height
    }
  }

  &-terms {
    .invalid-feedback.d-block {
      display: none !important;
    }
  }
}

.input-group {
  input {
    background-color: $white;
  }
}


/*Filter MOve to Forms?*/

.attribute {
  &-title {
    color: $body-color;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-left: 1rem;

  }
}

//Validation

.required-field {
  color: $gray-700;
}

.custom-control-label .invalid-feedback {
  display: none !important;
}

.invalid-feedback {
  font-style: italic;

  .badge {
    display: none;
  }
}