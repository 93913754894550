.carousel {
  &-background {
    background: url('../images/hintergrund_slider.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 270% 45%;
  }

  &-indicators {
    margin-bottom: 4rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
     
      & [data-bs-target] {
      height: 2px;
      width: 2rem;
      }

      .active {
        background-color: $blue;
        width: 2rem;
        height: 4px;
      }
  }

  }

  &-control-prev {
    // display: none;

    // @include media-breakpoint-up(md) {
      display: flex;
      opacity: 1;
      width: 13%;
  
      &-icon {
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="28.886" height="28.886" viewBox="0 0 28.886 28.886"><g id="baseline-arrow_forward-24px" transform="translate(0)"><path id="Pfad_1980" data-name="Pfad 1980" d="M0,0H28.886V28.886H0Z" transform="translate(0 0)" fill="none"/><path id="Pfad_1981" data-name="Pfad 1981" d="M13.629,4l-1.7,1.7,6.716,6.728H4v2.407H18.648l-6.716,6.728,1.7,1.7,9.629-9.629Z" transform="translate(0.815 0.814)" fill="%230076BD"/></g></svg>');
        transform: rotate(180deg);
        text-align: center;
        vertical-align: middle;
        background-position: center center;
        margin: 1rem;
      }
    // }

  }

  &-control-next {
    // display: none;
    
    // @include media-breakpoint-up(md) {
      display: flex;
      opacity: 1;
      width: 13%;
  
      &-icon {
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="28.886" height="28.886" viewBox="0 0 28.886 28.886"><g id="baseline-arrow_forward-24px" transform="translate(0)"><path id="Pfad_1980" data-name="Pfad 1980" d="M0,0H28.886V28.886H0Z" transform="translate(0 0)" fill="none"/><path id="Pfad_1981" data-name="Pfad 1981" d="M13.629,4l-1.7,1.7,6.716,6.728H4v2.407H18.648l-6.716,6.728,1.7,1.7,9.629-9.629Z" transform="translate(0.815 0.814)" fill="%230076BD"/></g></svg>');  
        text-align: center;
        vertical-align: middle;
        background-position: center center;
        margin: 1rem;
      }
    // }

  }

}

/*12 Product Carousel Styles Starts from shop.css*/
.product-carousel {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-carousel 
#owl-product .item {
  padding: 1rem;
  height: 350px;

  .caption {
    display: flex;                   /* defines flexbox */
    flex-direction: column;          /* top to bottom */
    justify-content: space-between;  /* first item at start, last at end */
  }
}

#owl-product {

  .owl-controls {
    margin: 0;

    .owl-buttons {
      div {
        position: absolute;
        background: rgba($white, .5);
        border-radius: 50%;
      }
    }
  }
}
                                            
.owl-carousel .owl-item {
  text-align:center;
}

.owl-prev, .owl-next
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.owl-prev
{
    left: -1rem; 
}

.owl-next
{
    right: -1rem;  
}

.owl-theme {

  .owl-controls {
    text-align: center;

    .owl-page {
      display: inline-block;

      span{
        display: block;
        width: 30px;
        height: 2px;
        margin: 5px 7px;
        filter: Alpha(Opacity=50);
        opacity: 0.5;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        background: $blue;
      }

      &.active span{
        opacity: 1;
        height: 4px;
      }
    }
  }
}
                               
/*12 Product Carousel Styles Starts Eo*/



