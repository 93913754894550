.badge {
  &-new {
    background-color: $turquoise;
  }

  &-sales {
    background-color: $red;
  }

  &-discount {
  }

  &-blue {
    background-color: $blue;

    &-cart {
      border: 2px solid $white;
      top: -21px !important;
      left: -12px !important;
    }
  }

  &-danger {
    min-width: 3.5rem;
  }

}

.currentfilter {
  .badge {
    background-color: $blue-light;
    border-color: $blue-200;
    color: $blue;
    font-size: $font-size-base * .875;
    padding: .625rem 1rem;
  }
}
