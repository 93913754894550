// Color system

$white:             #ffffff;
$gray-200:          #e8e8e8;
$gray-400:          #868e96;
$gray-500:          #818181;
$gray-600:          #707070;
$gray-700:          #545353;
$gray-900:          #191919;
$black:             #000000;

$red:               #d0192b;
$blue:              #0076bd;
$blue-light:        #e1f6f8;
$blue-dark:         #015181;
$blue-200:          #c9ebff;
$blue-500:          #0484D1;
$yellow:            #faba35;
$yellow-dark:       #e0a800;
$yellow-darker:     #bf8f00;
$turquoise:         #15c5d6;

$primary:           $yellow;
$secondary:         $blue;
$info:              $blue-light;

// Options

$enable-rounded:              false;
$enable-rfs:                  true;
// $enable-validation-icons:     false;


// Spacing

$spacer: 1rem;
// Create your own map
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
  6: $spacer * 3.125,
  7: $spacer * 4,
  8: $spacer * 8,
  9: $spacer * 9.5,
  10: $spacer * 10.5,
  11: $spacer * 13,
  12: $spacer * 5,
);


// Body

$body-color:        $gray-700;


// Links

$link-color:        $blue;
$link-decoration:   none;
$link-hover-color:  $blue-dark;


// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1680px,
  xxxxl: 1920px
);

// Grid containers

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);


// Components

$component-active-bg:         $secondary;


// Typography

$font-family-sans-serif:            'Lato', sans-serif;
$font-family-sans-serif-secondary:  'PT Sans', sans-serif;

$font-size-base:            1rem;

$font-weight-normal:        400;
$font-weight-bold:          700;

$line-height-base:          1.5625;

$h1-font-size:              $font-size-base * 2.1875; // 35px
$h2-font-size:              $font-size-base * 1.875; // 30px
$h3-font-size:              $font-size-base * 1.375; // 22px
$h4-font-size:              $font-size-base; // 16px
$h5-font-size:              $font-size-base * 0.8; // 8px


// Tables

$table-striped-bg:            $blue-light;
$table-striped-order:         even;
$table-group-separator-color: $blue-dark;

// $table-color:                 red;
// $table-bg:                    green;
$table-accent-bg:            $white;

// Buttons + Forms

// $input-btn-padding-y:         .625rem;
// $input-btn-padding-x:         1.25rem;

$input-btn-focus-width:       0;


// Buttons

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              none;


// Forms

$input-color:                              $blue-dark;
$input-border-color:                       $gray-200;

$form-check-input-border:                 1px solid $blue-dark;

$form-switch-color:               $input-color;
$form-switch-width:               2.5em;
$form-switch-height:              1.5em;  // custom var

$form-select-padding-x:             0.625rem;
$form-select-indicator-color:       $gray-700;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");


// Navs

$nav-tabs-border-width:             0;
$nav-tabs-link-active-color:        $white;
$nav-tabs-link-active-bg:           $blue;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;

// Navbar

$navbar-padding-y:                  0;

$navbar-nav-link-padding-x:         .625rem;

$navbar-dark-color:                 $white;


// Dropdowns

$dropdown-min-width:                15rem;
$dropdown-padding-y:                0;
$dropdown-spacer:                   .75rem;
$dropdown-bg:                       $blue-light;
$dropdown-border-width:             0;

$dropdown-link-color:               $blue;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            rgba($blue, .66);

$dropdown-item-padding-y:           0.65rem;
$dropdown-item-padding-x:           0.65rem;


// Accordion

$accordion-color:                         $blue-dark;

$accordion-button-active-bg:              $white;
$accordion-button-active-color:           $blue;


// Badges

$badge-font-weight:                 $font-weight-normal;
$badge-padding-x:                   0.95em;


// Breadcrumbs

$breadcrumb-font-size:              $font-size-base * 0.875;
$breadcrumb-divider-color:          $gray-400;
$breadcrumb-active-color:           $gray-400;


// Carousel

$carousel-control-color:            $blue;

$carousel-indicator-active-bg:      $blue;


// Custom Variables
$btn-block-spacing-y:         1rem;

// Icons

$icon-size:         1rem;
$icon-size-md:      1.5rem;
$icon-size-lg:      2rem;
$icon-size-xl:      3rem;
$icon-size-xxl:     4rem;

$link-icon-size:     14px;


$slider-title-font-size:    $font-size-base * 2.8125; // 45px
$slider-subtitle-font-size: $font-size-base * 1.5625; // 25px

$banner-font-size:          $font-size-base * 1.5625; // 25px

$font-nav-link:             0.6875rem;




// Spacing

.ml-n4 {
  margin-left: -2rem;
}

//Spacers
.spacer {
  &-50 {
    padding: 1.5625rem 0;
    display: block;
  }
  &-100 {
    padding: 3.125rem 0;
    display: block;
  }
  &-150 {
    padding: 4.6875rem 0;
    display: block;
  }
  &-200 {
    padding: 6.25rem 0;
    display: block;
  }
  &-250 {
    padding: 7.8125rem 0;
    display: block;
  }
  &-300 {
    padding: 9.375rem 0;
    display: block;
  }
}