.btn {
  min-width: 11.25rem;

  // Bisher nur in Toolbox, wirklich notwendig? Yep, wirklich :)
  &-blueLight {
    a {
      color: $white;
    }

    background-color: $blue-300;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: $blue-dark;
      color: $white;
    }
  }

  &-search {
    min-width: 2rem;
  }
}