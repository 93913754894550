.nav-tabs {
    box-shadow: 0 0 3px rgba($black, .1);

    .nav-link {
        font-weight: $font-weight-bold;
        background: $body-bg;
    }
}

.navbar-main-menu {
    font-weight: $font-weight-bold;
    font-size: $font-size-base * .875;

    .nav-link {
        padding: .625rem;
        background-color: $primary;
        color: $white;

        &:hover,
        &:focus,
        &.show {
            background-color: $secondary;
        }

        &.dropdown-toggle {
            padding-top: 0;
            padding-bottom: 0;
            min-width: auto;
        }
    }

    .dropdown-item {
        font-size: $font-size-base * .875;
    }

    &.navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);
        
            // stylelint-disable-next-line scss/selector-no-union-class-name
            &#{$infix} {
                @include media-breakpoint-up($next) {
                    .nav-item {
                        margin-left: .375rem;
                        margin-right: .375rem;
                    }
                }
            }
        }
    }
}
 
/* TODO: Toggler & mobile Navigation */
.navbar-toggler>.close {
    display:inline-block;
}
.navbar-toggler.collapsed>.close, .navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
    display:none;
}

/*Shop.css overrides*/
.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 118, 189, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

#navbarNav {
    background: linear-gradient(180deg, $white -93px, $blue-light 200px);

    .offcanvas-body {
        padding: 0;
    }
}

.navbar-light .meta-navbar-nav {
    .nav-item {
        border-bottom: 1px solid $white;
        padding: calc($spacer / 2) $spacer;

        &.dropdown > .btn-group {
            flex-wrap: wrap;

            > .dropdown-menu {
                flex: 0 1 100%;
            }
        }
    }
    .nav-link {
        color: $gray-700;
        font-weight: $font-weight-bold;

        &.dropdown-toggle {
            flex-grow: 0;
            min-width: auto;
            padding-left: $navbar-nav-link-padding-x;
            padding-right: $navbar-nav-link-padding-x;
        }
    }
}

/*More Categories Dropdown*/
.collect {
    #submenu {
        &.dropdown-menu.show {
            left: -10.7rem;

            li.nav-item {
                margin-left: 0;
                margin-right: 0;
                border-top: 1px solid #ffffff;
            }
        }
        a.nav-link {
            background-color: transparent;
            color: $blue;
            font-weight: $font-weight-normal;

            &:hover,
            &:active,
            &:focus {
                background-color: rgba(0, 118, 189, 0.66);
                color: $white;
            }
        }
    }
}
