@use "sass:math";

/* Schriftarten */
/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v20-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/lato-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v20-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v20-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
  }

/* pt-sans-regular - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/pt-sans-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/pt-sans-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/pt-sans-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/pt-sans-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/pt-sans-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/pt-sans-v12-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
  }
  
  /* pt-sans-700 - latin */
  @font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/pt-sans-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/pt-sans-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/pt-sans-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/pt-sans-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/pt-sans-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/pt-sans-v12-latin-700.svg#PTSans') format('svg'); /* Legacy iOS */
  }

/*Fonts definition*/
@mixin h1-init {
  @include font-size($h1-font-size);
  font-weight: $font-weight-bold;
  line-height: $h1-font-size + 0.375;
  font-family: $font-family-sans-serif-secondary;
}

@mixin h1-h2-init {
    @include font-size($font-size-base * 1.25);
    font-weight: $font-weight-normal;
    line-height: $h4-font-size + 0.375;
    font-family: $font-family-sans-serif;
}
h1, .h1 {
  @include h1-init;
  color: $blue;

   + h2, + .h2 {
    @include h1-h2-init;
     color: $body-color;
   }
}

h2, .h2 {
  @include font-size($h2-font-size);
  font-weight: $font-weight-bold;
  line-height: $h2-font-size + 0.375;
  font-family: $font-family-sans-serif-secondary;
  color: $blue;

  + h3, + .h3 {
    @include font-size($font-size-base * 1.25);
    font-weight: $font-weight-normal;
    line-height: $h4-font-size + 0.375;
    font-family: $font-family-sans-serif;
    color: $body-color;
  }
}

h3, .h3 {
  @include font-size($h3-font-size);
  font-weight: $font-weight-bold;
  line-height: $h3-font-size + 0.375;
  font-family: $font-family-sans-serif-secondary;
  color: $blue;
  //text-transform: capitalize;
}

h4, .h4 {
  @include font-size($h4-font-size);
  font-weight: $font-weight-bold;
  line-height: $h4-font-size + 0.375;
  font-family: $font-family-sans-serif;
  color: $blue-dark;
}

.file-download {
  display: block;

  &::before {
    content: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='17' viewBox='0 0 14 17'><path d='M19,9H15V3H9V9H5l7,7ZM5,18v2H19V18Z' transform='translate(-5 -3)' fill='#{$link-color}'/></svg>"));
    vertical-align: middle;
    margin-right: calc($spacer / 2);
  }
}

p {
  line-height: 24px;

  &.title {
    color: $blue-dark;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
}

/*Remove all gutters initialization*/
.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid{
  margin-left: 0;
  margin-right: 0;
}

.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

//containers
.container {
  &-indented {
    width: 92%;
    margin: 0 auto;
    height: 550px;

    @include media-breakpoint-up(md) {
      width: 75%;
      height: 330px;
    }

    @include media-breakpoint-up(lg) {
      width: 66%;
      height: 640px;
      padding: 5rem 0;
    }
  }
}

//sections
.section {
  &-login,
  &-acount {
    margin-top: 3rem;
    @include media-breakpoint-up(lg) {
      margin: 7rem 0;
    }
  
    .card-body {
      padding: 0 !important;
    }
  }

  &.menu-categories,
  &.product-carousel {
    padding: 0 2.8125rem;
  }
}

.breadcrumb{
      margin: 0 0 2rem 0rem;

    @include media-breakpoint-up(xxl) {
      margin: 0 0 4rem 0;
    }
}

.cursor-pointer {
  cursor: pointer;
}

.modal-close {
  min-width: 2rem !important;
}

.bg-yellow {
  background-color: $yellow;

  h1, h2, h3, h4, h5, h6, div, p {
    color: $white;
  }
}

.bg-blue {
  height: 100%;
  background: $blue-light;

  //Login/ Register/ Bereich
  .card {
    border: 0px solid #ffffff;
    background-color: transparent;

    &-smart,
    &-header {
      background: transparent !important;
    }
  }
}

.bg-blue-dark {
  background: $blue;
  
  h1, h2, h3, h4, h5, h6, div, p {
    color: $white;
  }
}

.bg-transparent {
  background-color: transparent;

  &.medium {
    padding: 0;
  }
}

.large,
.medium {
  margin: 0 auto;
  padding: 2.75rem;
}

.large {
  @include media-breakpoint-up(xl) {
    width:92%;
  }
}

.medium {
  @include media-breakpoint-up(xl) {
    width:75%;
  }
}

.img {
  display: block;
  &-center {
    margin: auto auto;
  }
  &-left {
    margin-left: 0;
    margin-right: auto;
  }
  &-right {
    margin-left: auto;
    margin-right: 0;
  }
}

.link-blue {
  color: $blue-dark;
  font-family: $font-family-sans-serif-secondary;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  
}

.link-gray {
  color: $body-color;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  font-size: $font-nav-link;

  &-text {
    justify-content: space-between;

    &-login {
      padding-left: 1rem;
      vertical-align: super;
    }
  }
  
}

.text-decoration {
  text-decoration: underline;
}

#logo {
  a {
    display: inline-block;
  }

  img {
    max-width: 8.4375rem;
  
    @include media-breakpoint-up(xl) {
      max-width: 100%;
    }
  }
}

.cart-asantys {
  #cart .dropdown-toggle::after {
    display: none;
  }
  
}

.anchor {
  margin-top: -1.5rem;
  z-index: 1;
  position: relative;
  padding: 0.5rem 1rem;
}
.accordion-asantys,
.anchor-asantys {
  .link-gray {
    font-size: initial;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    background-color: $white;
  }

  &.bg-white {
    background-color: $white;
  }

  & a{
    .icon-container {
     
      height: unset;
      width: unset;
    }
    &:hover {
      background-color: $blue-200;
      color: $body-color;
  
      .icon-container {
        background-color: $blue-200;
  
      }
    }

    &:active {
      background-color: $blue;
      color: $white;
  
      .icon-container {
        background-color: $blue;

        .arrow-down {
          background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.886 28.886"><g transform="translate(0)"><path d="M0,0H28.886V28.886H0Z" transform="translate(0 0)" fill="none"/><path d="M13.629,4l-1.7,1.7,6.716,6.728H4v2.407H18.648l-6.716,6.728,1.7,1.7,9.629-9.629Z" transform="translate(0.815 0.814)" fill="%23ffffff"/></g></svg>');
        }
  
      }
    }

  }
}

.accordion-asantys[aria-expanded=false] .text-expanded {
  display: none;
}
.accordion-asantys[aria-expanded=true] .text-collapsed {
  display: none;
}

/*Overwrites for Shop.css*/
/* Header Area Styles Starts */
#header-area {
  background: #fff;
  margin-bottom: 0;

  .header-top {
      background: $blue;
      /* Currency & Languages Drop Down Styles Starts */
      .header-links ul li {
        display: inline-block;

        &.active a {
          background: none;
          color: #9dd3aa;
        }
        a {
          padding: 12px 15px 12px 0;

          &:hover,
          &:focus{
            background: none;
            color: #9dd3aa;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  .main-header {
    padding: 25px 0 0px;
    
    #logo {
      text-align: left;
    }

    #search {
      margin: 0;

      .input-group {
        align-items: self-end;

        .form-control {
          border: none;
          background: $white;
          color: $gray-500;
  
          @include media-breakpoint-up(xl) {
            width: 25rem;
            border-bottom: solid $blue-light;
            border-width: 2px;
          }
        }

      }
    }
  }
}

.main-container {
  flex: 1 0 auto;
 
    @include media-breakpoint-up(lg) {
      padding-bottom: 12.5rem;
    }

}

#header-area .main-header {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.header-top li,
.header-top .btn-link,
#main-menu.navbar,
#search .input-group .form-control,
#cart .btn,
.product-head,
.product-col .caption h4,
.product-col .price,
.side-heading,
.list-group-item,
.product-filter,
.pagination,
.tabs-panel .nav-tabs {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    font-size: $font-nav-link;
}

.navbar-dark .navbar-nav .nav-link:hover
{
    color: $blue;
    background-color: $white;
}

/*3 Cart Style Starts */
#cart {
  ul {
    .btn {
      padding: 0.5rem 1rem;
      width: 100%;
      margin-bottom: 0.3rem;
    }
    a.btn {
      color: $white !important;
     }
  }
  .btn {
    border: $btn-border-width;
    text-align: right;
    padding: 0;
    margin: 0;
    font-size: 14px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &.btn-primary,
    &.btn-secondary {
      text-align: center;
    }

    span {
      color: $white;
  
      #cart-total {
        color: $blue;
        padding: 0 8px 0 5px;
      }
    }
  }

  .dropdown-menu {
    // min-width: 100%;
    margin-top: 10px;
    background: $blue-light;
    z-index: 1001;
    padding: 5px;

    table {
      td {
        background: none;
        border: none;

        a {
          color: $blue;
        }
      }

      &.total td {
        padding: 2px 8px;
      }
    }

    .table.hcart.cart-items > :not(caption) > * > *,
    .table.table-bordered.total > :not(caption) > * > * {
      padding: 0.2rem;
      font-size: 0.8rem;
      box-shadow: inset 0 0 0 9999px $blue-light;

      text-align: right;
    }


    li > div {
      height: 100px;
      overflow-y: scroll;

      @include media-breakpoint-up(md) {
        height: auto;
        overflow-y: auto;
      }
    }

    &.show {
      // margin-left: 345px;
      // width: 105%;
      @include media-breakpoint-up(sm) {
        // margin-left: 360px;
        // width: 100%;
      }

      @include media-breakpoint-up(xl) {
        // margin-left: -148px;
        // width: 333%;
      }
    }
  }

  .open > .dropdown-menu {
    display: block;
  }

}

.line-bottom {
    border-bottom: 1px solid $blue;
}

/*6 Tabs Styles Starts */
.tabs-panel {
  margin-top: 20px;

  &.card-smart {
    padding: 0;
  }

  .nav-tabs {
    font-size: 1rem;
    border-bottom: 4px solid $blue-light;

    > li {
      margin-bottom: 0;

      > a {
        color: $body-color;
        margin: 0;

        &:hover {
          background: $blue;
        }
      }
      &.active{
        background: $blue-light;
        color: $blue;

        > a {
          background: $blue-light;
          color: $blue;
        }
      }
    }
  }

  .nav-tabs > li > a,
  .btn,
  .form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}

.tabs-panel .nav-tabs > li.active > a,
.tabs-panel .nav-tabs > li.active > a:hover,
.tabs-panel .nav-tabs > li.active > a:focus,
.tabs-panel .nav > li > a:hover,
.tabs-panel .nav > li > a:focus {
  background: none;
}

.tabs-panel .nav-tabs > li > a,
.tabs-panel .nav-tabs > li.active > a,
.tabs-panel .nav-tabs > li.active > a:hover,
.tabs-panel .nav-tabs > li.active > a:focus,
.tabs-panel .nav-tabs > li > a:hover {
  border: none;
}

.tabs-panel .tab-content {
  padding: 20px;
  font-size: 13px;
}

.tabs-panel label.control-label {
  text-transform: uppercase;
  font-weight: normal;
}

.tabs-panel label.control-label.ratings {
  padding-top: 1px;
}

.tabs-panel .btn {
  margin-left: -10px;
}

/* 7 Pagination Styles Starts l.11912*/
.pagination {
    margin: 0;

    > li > a {
      color: #8b8b8b;
  }

    > li:first-child > a, 
    > li:first-child > span, 
    > li:last-child > a, 
    > li:last-child > span {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}

.page-item {
  &.active .page-link {
    background: $blue;
    border-color: $blue;
  }
  .page-link {
    &:hover,
    &:focus {
      background: $blue;
      border-color: $blue;
      color:white;
    }
  }
}

.results {
    padding-top: 10px;
    text-align: right;
}

.side-heading {
    margin-top: 0;
    padding: 12px 20px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background: $blue;
}

.group,
.list-group-item:first-child,
.list-group-item:last-child {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.list-group.categories {
    margin-bottom: -1px;
    justify-content: center;

    a.list-group-item {
      &:hover,
      &:focus,
      &.active {
        background-color: $blue;
        border-color: white;
        color: #fff;
      }
    }

    &:last-child {
      margin-bottom: 20px;
  }

  .list-group {
    .list-group-item {
      padding-left: 25px;
    }

    .list-group .list-group-item {
      padding-left: 45px;
    }
  } 
}

.footer {
  padding: 1.5rem 0 5rem;
  background: linear-gradient(180deg, $blue-light 545px, $white 700px);
    
  @include media-breakpoint-up(md) {
    background: linear-gradient(180deg, $blue-light 85px, $white 235px);
  }

  .logo {
    display: block;
    width: 10.5rem;
    
    @include media-breakpoint-up(md) {
      width: 12.75rem;
    }
  }

  .nav-title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    font-family: $font-family-sans-serif-secondary;
    
    @include media-breakpoint-up(md) {
      @include font-size($font-size-base * 1.125);
    }
  }

  .nav-link {
    padding: 0;
    color: $body-color;
  }
  
  .copyright {
    font-weight: bold;
  }

  &-email-logo img{
    height: 50px;
    width: auto;
  }

}

.footer-teaser {
  height: 100%;
  padding: 1.5rem 1rem;
  background-color: rgba($white, 0.6);
  position: relative;
  display: grid; 
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr auto;
  gap: 0 1.75rem; 
  grid-template-areas: 
    "icon text text"
    "icon link arrow";

  &-icon {
    width: 2.25rem;
    grid-area: icon;
  }

  &-text {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    font-family: $font-family-sans-serif-secondary;
    color: $body-color;
    grid-area: text;
    
    @include media-breakpoint-up(md) {
      @include font-size($font-size-base * 1.25);
      line-height: $h4-font-size + 0.375;
    }
  }

  &-link {
    @include font-size($font-size-base * 0.75);
    grid-area: link;
    align-self: center;
  }

  &-arrow {
    width: 1.75rem;
    grid-area: arrow;
    align-self: center;
  }
}

/* 8 Footer Links Styles Starts l.12006*/
.footer-links {
  border-top: 1px solid #929497;
  padding: 25px 0 40px;
 }


/*13 Products Box Styles Starts l.12127*/
.products-list {
  margin-bottom: 15px;
}

.product-head {
  padding: 14px 20px;
  margin: 0 0 20px;
  font-size: 24px;
  background: #252a2f;
  color: #fff;
  text-transform: uppercase;
}

.product-col {
  padding: 15px 15px 10px;
  border: 1px solid #e8e8e8;
  height: 100%;

  .description {
    padding: 5px 0;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    color: #8b8b8b;
  }

  .price {
    padding: 10px 0;

    .price-tax {
      font-size: 14px;
      color: #212121;
      text-transform: uppercase;
    }

    span.price-new {
      color: #2f353b;
      font-size: 24px;
      padding-right: 5px;
    }

    span.price-old {
      font-size: 18px;
      color: #808080;
      text-decoration: line-through;
    }
  }

  .cart-button {
    padding-top: 10px;

    .cart-item-quantity {
      max-width: 70px;
      margin-left: 7px;
    }
  }

  .cart-button .btn-wishlist,
  .btn-compare {
    display: inline-block;
    color: #fff;
    background: #303030;
    margin-right: 3px;
  }

  &.list {
    .image {
      padding: 20px 30px 10px 0;
      float: left;
    }

    .description {
      padding-right: 20px;
    }
  }
}

.cat-intro {
  padding-top: 10px;

  .img-thumbnail {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .cat-body {
    color: #8b8b8b;
    font-size: 13px;
    padding-left: 5px;
    text-align: justify;
  }
}

.product-filter {
  margin: 30px 0;
  padding: 8px 0;
  border: solid #e8e8e8;
  border-width: 1px 0;

  .display {
    padding-top: 5px;
    padding-left: 10px;

    a {
      color: #2f353b;
      font-size: 24px;
      margin-right: 6px;

      &.active {
        color: $blue;
      }
    }
  }

  .control-label {
    padding-top: 5px;
    font-size: 18px;
    font-weight: normal;
  }

  .form-control {
    height: 30px;
    padding: 3px 6px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}



#export-item {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

// Filter Styles
.filters {
  .filter-item {
    margin-bottom: $spacer * 2;

    legend {
      font-weight: $font-weight-bold;
    }
  }
}

/*15 Shopping Cart Page Styles Starts l.12461*/
.shopping-cart-item {
  .input-group-text {
    background-color: transparent;
    border: 1px solid transparent;
  }
}
.cart-item-price span {
  &.price-old {
    color: $gray-500;
    text-decoration: line-through;
  }
}
/* Shopping Cart Page Styles Starts Eo*/

/*16 Compare Page Styles Starts l.12493*/
.compare-table {
  margin-top: 2rem;

  thead {
    background: $blue-light;
    color: $blue-dark;

    > tr > td {
      font-weight: 600;
      border-bottom: none;
    }
  }

  tbody > tr > td {
    font-size: 14px;
  }

  td {
    text-align: center;
    vertical-align: middle;
  }
}

.order-table {
  margin-top: 30px;
  background: #fff;

  thead {
    background: $blue-light;

    > tr > td {
      font-weight: 600;
      border-bottom: none;
    }
  }

  tbody > tr > td {
    font-size: 14px;

    a:hover {
      color: $blue-dark;
    }
  }
}


.table {

  > :not(:first-child) {
    border-top: 2px solid $blue-200;
  }

  tr {
    border-bottom: transparent;
  }

  > :not(caption) > * > * {
    padding: 0.5rem 1rem;
    box-shadow: inset 0 0 0 9999px transparent;
  }
} 


#tableOrderItems {
  .table > :not(caption) > * > *,
  &.table > :not(caption) > * > * {
    padding: 0.5rem 1rem;
    box-shadow: inset 0 0 0 9999px $blue-light;
  }

}

/*17 Login & Registration Section Styles Starts l.12546*/
.registration-area {
  .form-control,
  .btn {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .dl-horizontal {

    dt {
      font-weight: normal;
      margin-bottom: 5px;
    }

    dd {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &.total {
      color: $blue;
      font-size: 18px;

      dt, dd {
        margin-bottom: 0;
      }
    }
  }
}

.card-smart {
  padding: 1.5rem 0 2rem;
  border: 1px solid #e8e8e8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  .card-header {
    padding: 5px 0;
    color: #252a2f;
    border-bottom: 0px solid #929497;
    background: white;
  }

  p {
    margin-bottom: 0;
  }
}

/*18 Content Box Styles Starts l.12616*/
.content-box {
  border: 1px solid #e8e8e8;
  background: #fff;
  margin-top: 20px;
  padding: 30px;

  .special-heading {
    font-size: 100px;
    color: $blue;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

/*19 Contact Details Box Styles Starts l.12631*/
.contact-details li {
  + li {
    margin-top: 20px;
  }
}

.card-smart blockquote {
  margin: 0;
  padding: 5px 15px;
  color: #8b8b8b;
  font-size: 13px;
  border: none;

  footer {
    padding-top: 10px;
    color: #252a2f;
    font-size: 14px;
  }
}

/*20 Form Wizard l.12660*/
.bs-wizard {
  margin-top: 40px;
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 30px 0;

  > .bs-wizard-step {
    padding: 0;
    position: relative;
    margin-top: 0;

    .bs-wizard-stepnum {
      color: #595959;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .bs-wizard-info {
      color: #999;
      font-size: 14px;
    }

    > .bs-wizard-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      background: $blue;
      top: 45px;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;

      &:after {
        content: ' ';
        width: 14px;
        height: 14px;
        background: white;
        border-radius: 50px;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    > .progress {
      position: relative;
      border-radius: 0px;
      height: 8px;
      box-shadow: none;
      margin: 20px 0;

      > .progress-bar {
        width: 0px;
        box-shadow: none;
        background: $blue;
      }
    }

    &.complete > .progress > .progress-bar {
      width: 100%;
    }

    &.active > .progress > .progress-bar {
      width: 50%;
    }

    &:first-child {
      &.active > .progress > .progress-bar {
        width: 0%;
      }

      > .progress {
        left: 50%;
        width: 50%;
      }
    }

    &:last-child {
      &.active > .progress > .progress-bar {
        width: 100%;
      }

      > .progress {
        width: 50%;
      }
    }

    &.disabled {
      > .bs-wizard-dot {
        background-color: #f5f5f5;

        &:after {
          opacity: 0;
        }
      }

      a.bs-wizard-dot {
        pointer-events: none;
      }
    } 
  }
}


@media (max-width: 767px) {
  .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
      font-size: 14px;
  }
}

.delivery-options .delivery-option {
  border: solid 1px #ccc;
  padding: 20px 0;

  .delivery-option-radio, 
  .delivery-option-image, 
  .delivery-option-text,
  .delivery-option-price {
    border-right: solid 1px #ccc;
    vertical-align: middle;
  }
}

.payment-options .payment-option {
  border: solid 1px #ccc;
  padding: 20px 0;

  .payment-option-radio, 
  .payment-option-image, 
  .payment-option-text,
  .payment-option-price {
    border-right: solid 1px #ccc;
    vertical-align: middle;
}
}

/*Form Wizard Eo*/

/*22 bootstraptoggle l.13090*/
.checkbox label .toggle,
.checkbox-inline .toggle {
    margin-left: -20px;
    margin-right: 5px;
}

.toggle {
    position: relative;
    overflow: hidden;

    input[type="checkbox"] {
      display: none;
  }

  &-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left 0.35s;
    -webkit-transition: left 0.35s;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  // &.off, &-group{
  //   left: -100%;
  // }

  &-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  &-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  &-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    width: 0px;
    border-width: 0 1px;
  }

  &.btn {
    min-width: 59px;
    min-height: 34px;
  }

  &-on.btn {
    padding-right: 24px;
  }

  &-off.btn {
    padding-left: 24px;
  }
}


.toggle.btn-lg, .btn-group-lg > .toggle.btn {
    min-width: 79px;
    min-height: 45px;
}

.toggle-on.btn-lg, .btn-group-lg > .toggle-on.btn {
    padding-right: 31px;
}

.toggle-off.btn-lg, .btn-group-lg > .toggle-off.btn {
    padding-left: 31px;
}

.toggle-handle.btn-lg, .btn-group-lg > .toggle-handle.btn {
    width: 40px;
}

.toggle.btn-sm, .btn-group-sm > .toggle.btn {
    min-width: 50px;
    min-height: 30px;
}

.toggle-on.btn-sm, .btn-group-sm > .toggle-on.btn {
    padding-right: 20px;
}

.toggle-off.btn-sm, .btn-group-sm > .toggle-off.btn {
    padding-left: 20px;
}

.toggle.btn-xs, .btn-group-xs > .toggle.btn {
    min-width: 35px;
    min-height: 22px;
}

.toggle-on.btn-xs, .btn-group-xs > .toggle-on.btn {
    padding-right: 12px;
}

.toggle-off.btn-xs, .btn-group-xs > .toggle-off.btn {
    padding-left: 12px;
}

.registration-area label.cs-customer, .registration-area label.cs-address {
    font-size: 26px;
    padding: 5px 0 15px 0;
    color: #252a2f;
    border-bottom: 1px solid #929497;
    margin-top: 30px;
    margin-bottom: 20px;
    display:block;
}

.cart-summary {
    padding-bottom: 20px;
}
/*bootstraptoggle Eo*/

/*23 PR fixes l.13234*/
.header-links .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 1rem;
  padding-top:0.8em;
}

.open .dropdown-menu {
  display: block;
}


html {
position: relative;
min-height: 100%;
}
body {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background: linear-gradient(180deg, $white 725px, $blue-light 945px);
  overflow-x: hidden;
}
/*PR fixes Eo*/

/*
 *  24 Bootstrap TouchSpin - v4.2.5 l.13266
 */
 .bootstrap-touchspin {

  .input-group-btn-vertical {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 11;

    > .btn {
      position: absolute;
      right: 0;
      height: 50%;
      padding: 0;
      width: 2em;
      text-align: center;
      line-height: 1;
      min-width: initial;
    }

    .bootstrap-touchspin-up {
      border-radius: 0;
      top: 0;
    }

    .bootstrap-touchspin-down {
      border-radius: 0;
      bottom: 0;
    }
  }

 } 



/*
 *  Bootstrap TouchSpin Eo
 */


.info-product {
  font-size: 0.875rem;
  color: $blue;
}

.category {
  &-row {
    .info-product {
      &-show {
        visibility: hidden;
      }
        
    }
  
    &:hover {
      .info-product {
        &-show {
          visibility: visible;
        }
      }
    }
  }
}

.product-col {
  border: 1px solid $white;
  background-color: $white;
  height: 100%;

  a{
    text-decoration: none;

    &:hover {
        color: $blue;
    
    }
  }

  .info-product {
    &-show {
      visibility: hidden;
    }
      
  }

  &:hover {
    .info-product {
      &-show {
        visibility: visible;
      }
    }
  }

}

.product-col .caption {
  padding: 0;
  color: $body-color;
  font-size: $h5-font-size;
  font-family: $font-family-sans-serif-secondary;
  font-weight:$font-weight-bold;

  &.description {
    font-size: 0.8125rem;
    color: $gray-500;
    font-family: $font-family-sans-serif;
    font-weight:$font-weight-normal;
    font-style: italic;
    text-align: left;
    margin: 0;
  }
}

.product-col .caption .price {
  color: $blue;
  font-family: $font-family-sans-serif;
  font-weight:$font-weight-bold;
}

.product-head {
  background: none;
}

.list-group.categories a.list-group-item:hover, .list-group.categories a.list-group-item:focus, .list-group.categories a.list-group-item.active {
  background-color: $blue;
  color: $blue;
}

.list-group.categories a.list-group-item:hover, .list-group.categories a.list-group-item:focus, .list-group.categories a.list-group-item.active {
  background-color: $blue;
}

.list-group-item {
  color: $blue;
  font-size: 0.875rem; //14px
  text-transform: none;
  font-weight: $font-weight-normal;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.product-filter .display a.active {
  color: $blue;
}

/* Product Page Styles Starts */
.product-info {
  .product-details {
    ul li::marker { 
      color: $blue;
    }

    // TODO: überarbeiten
    .price {
      &.default-unit-price {
        .extra-info {
          font-weight: $font-weight-normal;
          font-style: italic;
          font-size: $font-nav-link;
          color: $gray-200;
        }

        
        .selectBox.show {
          .dropdown-menu {
            margin-top: 0;
          }

          .dropdown-item {
            &.active {
              .price-new,
              .price-head,
              .extra-info {
                color: $white;
              }

            }

            .price-wrapper{
              padding: 1rem 1.5rem;
              &.active {
                background-color: $blue;
                .price-new,
                .price-head,
                .extra-info {
                  color: $white;
                }
      
              }
            }
          }
        }

      }
    }
  }

  &.full {
    .images-block {
      .list-inline {
        margin-left: -5px;

        li {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }

  &-box {
    .heading {
      padding: 13px 20px;
      font-size: 18px;
      color: $blue;
      text-transform: uppercase;
      background: #fff;
      border-top: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      border-bottom: 4px solid $blue;
      border-left: 1px solid #e8e8e8;
    }

    .content {
      color: #212121;
      font-size: 13px;
    }
  }
}


.registration-area .dl-horizontal.total {
  color: $blue;
}

.content-box .special-heading {
  color: $blue;
}


.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  background: $blue;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  background: $blue;
}


#cart .btn {
  color: $blue;
}

.line-top {
    border-top: 1px solid $blue;
}

/*Eo Overwrites shop.css*/



.slider {
  &-selection {
    background: $blue;
  }

  &-title {
    font-size: $slider-subtitle-font-size;
    font-family: $font-family-sans-serif-secondary;
    font-weight: $font-weight-bold;
    line-height: $headings-line-height;
    color: $blue;
    padding: 1rem 0 0 0;
    @include media-breakpoint-up(lg) {
      font-size: $slider-title-font-size;
      padding: 2rem 0 4rem 0;
    }
  }

  &-subtitle, &-subtitle p {
    font-size: $font-size-base;
    font-family: $font-family-sans-serif;
    line-height: $headings-line-height;
    color: $gray-600;
    @include media-breakpoint-up(lg) {
      font-size: $slider-subtitle-font-size;
      padding-bottom: 2rem;
    }
  }
}

/*banner*/

.banner {
  &-blue {
    padding: 2rem;
    background-color: $blue;
    color: $white;

    p {
      font-size: $banner-font-size;
      font-weight: $font-weight-bold;
      font-family: $font-family-sans-serif-secondary;
      color: $white;
    }

    small {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      font-family: $font-family-sans-serif;
      color: $white;
    }
  }
}

/*Stock status*/
.stock {
  display: flex;
  align-items: first baseline;

  &::before {
    content: '';
    display: block;
    width: $font-size-base;
    height: $font-size-base;
    border-radius: 50%;
    margin-right: .75rem;
  }
}

.in-stock::before {
  background-color:#0acc37;
}

.out-of-stock-yellow::before {
  background-color:yellow;
}

.out-of-stock-gray::before {
  background-color:gray;
}

.out-of-stock-black::before {
  background-color: #1d1d1d;
}

/*nouislider*/
.content-range-slider {
	width: 93%;
  margin: 5rem 8px auto;
	text-align: center;
}

.range-slider {
	display: flex;
	align-items: center;
  width: 100%;
  height: 14px;
	column-gap: 0px;
	background: inherit;
	padding: 0px;
	border-radius: 50px;
	background-color: $blue-light;
	backdrop-filter: blur(100px);

	&-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		column-gap: 20px;
	}

	.noUi-target {
		flex: 1;
		position: relative;
		background: $blue-light;

		.noUi-base,
		.noUi-connects {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
		}

		.noUi-connects {
			border-radius: 3px;
			overflow: hidden;
			z-index: 0;
		}

	}

	.noUi-draggable {
		cursor: ew-resize;
  }
  
  .noUi-handle {
    border: 1px solid $blue;
    border-radius: 50px;
    background: $blue;
    box-shadow: none;
  
    &::before,
    &::after {
      content: none;
    }
  
    .noUi-tooltip {
      border: 1px solid $gray-600;
      background: $gray-600;
      color: $white;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base * 0.875;
      bottom: 150%;

      &:after {
        z-index: -1;
        position: absolute;
        top: 100.1%;
        left: 50%;
        margin-left: -11%;
        content: "";
        width: 0;
        height: 0;
        border-top: solid 8px $gray-600;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
  }
  
  .noUi-horizontal {
    height: 14px !important;
  }

  .noUi-connect {
    height: 14px;
    width: 100%;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
    background-color: $blue;
    opacity: 0.6;
  }

  .noUi-marker-horizontal.noUi-marker {
    margin-left: unset;
    width: 0px;
    height: 0px;
  }

  .noUi-pips-horizontal {
    padding: 0;
    height: 0;
    left: 23px;
    width: 87%;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base * 0.875;
  }

  .noUi-pips {
    color:$gray-900;
  }

}

.noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
  top: -5px;
}

.noUi-target {
  border: 1px solid $blue-200;
}
/*EoRange*/

.mobile {
  #cart {
    .btn {
      min-width: auto;
    }
  }
}

.desktop {
  div {
    #cart {
      .dropdown-toggle::after {
        display: none;
      }
      .btn {
        min-width: 5.25rem;
        text-align: center !important;
      }
      ul {
        min-width: 20rem;
      }
    }
  }
}

.table-striped {
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  > :not(:first-child) {
    border-top: (2 * $table-border-width) solid $blue-200;
  }
}

.usercentrics-button.uc-button-bl .uc-privacy-button-wrapper {
  height: 140px;
}
